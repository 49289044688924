import React from 'react'
import { useTranslations } from '../../hooks/use-translations'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'

import * as s from '../../pages/news.module.scss'

const ToolsPage: React.FC = () => {
  const { locale } = useTranslations()
  return (
    <>
      <SEO
        title="Tools"
        description={
          locale === 'en'
            ? 'Технический вебинар SIGNAL TOOLS'
            : 'Технический вебинар SIGNAL TOOLS'
        }
      />
      <NewsSection
        title="Технический вебинар SIGNAL TOOLS"
        dateData={{ date: '24.10.2021', event: 'Вебинар' }}
      >
        <p className={s.text}>
          2 октября прошел большой технический вебинар, посвящённый SIGNAL
          TOOLS. На нем Александр Попов, технический директор Signal, провел
          живой показ и рассказал про принципы работы с BIM-моделью на
          строительной площадке, про автоматическую классификацию её элементов,
          проверку информационных моделей на соответствие требований EIR и
          проверку соответствия План-факт при помощи функционала модуля. Вебинар
          был необычайно популярен и собрал около 200 участников. Ниже вы можете
          посмотреть его записью.
        </p>
        <div className={s.video}>
          <iframe
            className={s.videoSize}
            width="824"
            height="461"
            src="https://www.youtube.com/embed/FkwNjUuZW6o"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </NewsSection>
    </>
  )
}

export default ToolsPage
